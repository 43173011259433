<template>
  <div class="header">
    <div class="header-content th-container">
      <div class="website-logo">
        <router-link :to="{name: 'home'}">
          <img src="@/assets/images/websiteLogo.svg" alt="Logo">
        </router-link>
      </div>
      <div class="nav">
        <div class="search-area">
          <svg
              @click="toUsersPage"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
          >
            <path
                d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                stroke="#D9E1E4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M17.5 17.5L13.875 13.875"
                stroke="#D9E1E4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
          </svg>
          <base-input
              :input-type="'text'"
              :placeholder="'Search creators, gifters...'"
              v-model="searchQuery"
          />
          <div v-click-outside="hideSearchDropDown" v-if="searchedUsers.length && searchShowDropDown"
               class="search-dropdown">
            <div v-for="(user, index) in searchedUsers" :key="index"
                 @click="openSearchProfile(user.username)" class="search-dropdown-item">
              <div class="search-dropdown-item-img">
                <img v-if="user.avatar" :src="user.avatar" alt="#">
                <img v-else src="@assets/images/user-default.png" alt="#">
              </div>
              <div class="search-dropdown-item-text">
                <p class="username">{{ user.username }}</p>
                <p class="name">{{ user.name }}</p>
              </div>
            </div>
            <div v-if="searchedUsers.length > 5" @click="toUsersPage"
                 class=" justify-content-center search-dropdown-item cursor-pointer">
              <div class="search-dropdown-item-text">
                <p class="username">View More</p>
              </div>
            </div>
          </div>

          <div v-click-outside="hideSearchDropDown" v-else-if="!searchedUsers.length && searchShowDropDown"
               class="search-dropdown">
            <template>
              <div class="search-dropdown-item">
                <div class="search-dropdown-item-text">
                  <p class="username">Results not found</p>
                </div>
              </div>
            </template>
          </div>

        </div>
        <div class="nav-items">
          <router-link class="link-btn" :to="{name: 'leaderboard'}">
            Leaderboard
          </router-link>

          <span v-if="authenticated" class="header-line"></span>

          <div class="header_dashboard_icon">
            <router-link :to="{name: 'dashboard'}">
              <DashboardIcon v-if="authenticated && $store.state.siteMode === 'light'"
                             class="header-chat-image"/>
              <img v-if="authenticated && $store.state.siteMode === 'dark'" class="header-chat-image"
                   src="@assets/images/chat-icon-white.png">
            </router-link>
            <div v-if="authUser?.unread_notifications" class="unread_notifications"></div>
          </div>

          <div class="header-profile-icon">

            <img v-click-outside="closeProfileDropDown" @click="openProfileDropDown"
                 v-if="authenticated && $store.getters['auth/user'].avatar"
                 class="header-image header-profile-image"
                 :src="$store.getters['auth/user'].avatar">

            <img v-click-outside="closeProfileDropDown" @click="openProfileDropDown"
                 v-else-if="authenticated"
                 class="header-image"
                 src="@assets/images/user-default.png">

            <Transition>
              <div v-click-outside="hideProfileDropdown" v-if="profileDropDown" class="profile-dropdown">
                <div class="profile-dropdown-item">
                  <p>
                    <router-link :to="{name: 'user-profile', params: {username: $store.getters['auth/user'].username}}">Profile</router-link>
                  </p>
                </div>
                <div class="profile-dropdown-item">
                  <p>
                    <router-link :to="{name: 'settings'}">Settings</router-link>
                  </p>
                </div>
                <div class="profile-dropdown-item">
                  <p @click="logout()" v-if="authenticated">Logout</p>
                </div>
              </div>
            </Transition>
          </div>

          <button v-if="!authenticated" class="link-btn">
            <span @click="logout()" v-if="authenticated" class="link-btn">
              Logout
          </span>
            <router-link v-else class="link-btn" :to="{name: 'login'}">
              Log in
            </router-link>
          </button>
          <button v-if="!authenticated" class="secondary">
            <router-link class="header-link" :to="{name: 'register'}">
              Get started
            </router-link>
          </button>
        </div>
      </div>
    </div>

    <div @click="toggleBurger" class="burger-menu"
         :class="{'open': openProfileDropdownMobile}">
      <span class="burger-line"></span>
      <span class="burger-line"></span>
      <span class="burger-line"></span>
      <span class="burger-line"></span>
    </div>

    <div class="profile-dropdown-main">
      <div v-if="openProfileDropdownMobile"
           class="profile-dropdown-mobile">
        <div class="search-area">
          <svg
              @click="toUsersPage"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
          >
            <path
                d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                stroke="#D9E1E4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M17.5 17.5L13.875 13.875"
                stroke="#D9E1E4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
          </svg>
          <base-input
              :input-type="'text'"
              :placeholder="'Search creators, gifters...'"
              v-model="searchQuery"
          />
          <div v-click-outside="hideSearchDropDown" v-if="searchedUsers.length && searchShowDropDown"
               class="search-dropdown">
            <div v-for="(user, index) in searchedUsers" :key="index"
                 @click="openSearchProfile(user.username)" class="search-dropdown-item">
              <div class="search-dropdown-item-img">
                <img v-if="user.avatar" :src="user.avatar" alt="#">
                <img v-else src="@assets/images/user-default.png" alt="#">
              </div>
              <div class="search-dropdown-item-text">
                <p class="username">{{ user.username }}</p>
                <p class="name">{{ user.name }}</p>
              </div>
            </div>
            <div v-if="searchedUsers.length > 5" @click="toUsersPage"
                 class=" justify-content-center search-dropdown-item cursor-pointer">
              <div class="search-dropdown-item-text">
                <p class="username">View More</p>
              </div>
            </div>
          </div>

          <div v-click-outside="hideSearchDropDown"
               v-else-if="!searchedUsers.length && searchShowDropDown"
               class="search-dropdown">
            <div class="search-dropdown-item">
              <div class="search-dropdown-item-text">
                <p class="username">Results not found</p>
              </div>
            </div>
          </div>

        </div>
        <router-link v-if="authenticated" :to="{name: 'dashboard'}"
                     @click.native="closeProfileDropDownMobile"
                     class="profile-dropdown-item">
          <p>
            Dashboard <span class="">1</span>
          </p>
        </router-link>
        <router-link v-if="authenticated" :to="{name: 'user-profile', params: {username: $store.getters['auth/user'].username}}" @click.native="closeProfileDropDownMobile"
                     class="profile-dropdown-item">
          <p>
            Profile
          </p>
        </router-link>
        <router-link :to="{name: 'leaderboard'}" @click.native="closeProfileDropDownMobile"
                     class="profile-dropdown-item">
          <p>
            Leaderboard
          </p>
        </router-link>
        <router-link v-if="authenticated" :to="{name: 'settings'}"
                     @click.native="closeProfileDropDownMobile" class="profile-dropdown-item">
          <p>
            Settings
          </p>
        </router-link>
        <div v-if="authenticated" class="profile-dropdown-item">
          <p @click="logout()" v-if="authenticated">Logout</p>
        </div>
        <router-link v-else :to="{name: 'login'}" @click.native="closeProfileDropDownMobile"
                     class="profile-dropdown-item">
          <p>
            Log in
          </p>
        </router-link>
        <button v-if="!authenticated" class="secondary w-136 fz-14 mb-50">
          <router-link class="header-link" :to="{name: 'register'}" @click.native="closeProfileDropDownMobile">
            Get started
          </router-link>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import BaseInput from '../../UI/BaseInput'
import vClickOutside from 'click-outside-vue3'
import DashboardIcon from "@/router/components/icons/DashboardIcon.vue";
import preloader from "@/mixins/preloader";

export default {
  name: 'MainHeader',
  directives: {
    clickOutside: vClickOutside.directive
  },
  mixins: [preloader],
  components: {
    DashboardIcon,
    'base-input': BaseInput
  },
  data() {
    return {
      searchShowDropDown: false,
      searchQuery: null,
      searchedUsers: [],
      profileDropDown: false,
      openProfileDropdownMobile: false,
    }
  },
  mounted() {
    if (!localStorage.getItem('mode')) {
      localStorage.setItem('mode', 'light')
      this.$store.state.siteMode = 'light';
    } else if (localStorage.getItem('mode') === 'dark') {
      document.body.classList.add('dark')
      this.$store.state.siteMode = 'dark';
    } else if (localStorage.getItem('mode') === 'light') {
      document.body.classList.remove('dark')
      this.$store.state.siteMode = 'light';
    }
  },
  computed: {
    ...mapGetters({authenticated: 'auth/authenticated', authUser: 'auth/user',})
  },
  watch: {
    searchQuery(n) {
      if (n) {
        this.searchShowDropDown = true
        this.getAllUsers();
      }
    },
    openProfileDropdownMobile(n) {
      if (n) {
        document.querySelector('html').classList.add('overflow-hidden')
      } else {
        document.querySelector('html').classList.remove('overflow-hidden')
      }
    }
  },
  methods: {
    ...mapActions({
      logoutStore: 'auth/logout',
      getCurrency: "getCurrency"
    }),
    logout() {
      this.setLoading(true)
      this.axios.post('logout').then(() => {
        this.logoutStore();
        this.getCurrency();
        localStorage.removeItem('access_token');
        if (this.$route.name !== 'home') {
          this.$router.push({name: 'home'})
        }
        this.profileDropDown = false
        this.setLoading(false)
      })
    },
    changeMode() {
      if (localStorage.getItem('mode') === 'light') {
        localStorage.setItem('mode', 'dark')
        document.body.classList.add('dark')
        this.$store.state.siteMode = 'dark';
      } else if (localStorage.getItem('mode') === 'dark') {
        localStorage.setItem('mode', 'light')
        document.body.classList.remove('dark')
        this.$store.state.siteMode = 'light';
      }
    },
    getAllUsers() {
      this.axios.post('search-user', {
        searchQuery: this.searchQuery
      })
          .then((res) => {
            this.$data.searchedUsers = res.data.data
          }).catch(() => {
        this.toast.error('Error')
      })
    },
    hideSearchDropDown() {
      if(!this.searchedUsers.length){
        this.searchShowDropDown = false
      }
    },
    openSearchProfile(username){
      this.hideSearchDropDown()
      this.searchQuery = null
      this.searchedUsers = []
      this.$router.push({name: 'user-profile', params: {username: username}})
      this.openProfileDropdownMobile = false
    },
    toUsersPage() {
      this.searchShowDropDown = false
      if (this.$route.name !== 'users') {
        this.$router.push({name: 'users'})
      }
      this.$store.state.headerSearchQuery = this.searchQuery
    },
    openProfileDropDown() {
      this.profileDropDown = !this.profileDropDown;
    },
    closeProfileDropDown() {
      this.profileDropDown = false;
    },
    closeProfileDropDownMobile() {
      if (this.openProfileDropdownMobile) {
        this.openProfileDropdownMobile = false
      }
    },
    // hideProfileDropDownMobile(e) {
    //   if (this.openProfileDropdownMobile && !e.target.parentElement.classList.contains('burger-menu')) {
    //     console.log('hideProfileDropDownMobile')
    //     this.openProfileDropdownMobile = false
    //   }
    // },
    hideProfileDropdown(e) {
      if (this.profileDropDown && !e.target.classList.contains('header-image')) {
        this.profileDropDown = false
      }
    },
    toggleBurger(){
      this.openProfileDropdownMobile = !this.openProfileDropdownMobile
    }
  }
}
</script>

<style lang="scss">
@import "./MainHeader.scss";
</style>
